import { Tab } from '@headlessui/react'
import classNames from 'classnames'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import LogoLink from 'components/logoLink'
import { ReactChild } from 'data/types/types'

export const AuthSectionLayoutId = 'AuthSectionLayout'

interface AuthSectionLayoutProps {
  showLogo?: boolean
  selectedIndex?: number
  setSelectedIndex?: (index: number) => void
  headers: {
    title: string
    href: string
  }[]
  children: ReactChild
}

const AuthSectionLayout = ({
  showLogo,
  selectedIndex,
  setSelectedIndex,
  headers,
  children,
}: AuthSectionLayoutProps) => {
  return (
    <Tab.Group
      id={AuthSectionLayoutId}
      as="div"
      className="flex flex-col flex-grow h-full overflow-y-auto"
      selectedIndex={selectedIndex}
      onChange={setSelectedIndex}
    >
      {
        showLogo && (
          <div className="max-w-[127px] w-full mx-auto pt-20">
            <LogoLink
              className="mx-auto mt-10"
              variant="no-tag-line"
            />
          </div>
        )
      }

      <Tab.List className="sticky top-0 z-10 flex pt-20 bg-white px-37 gap-x-16">
        {
          headers.map((header, index) => (
            <Tab
              key={index}
              className="flex w-full text-center focus:outline-none"
            >
              {({ selected }) => (
                <Paragraphs.SM
                  className={classNames(
                    '!leading-22 font-bold tracking-1/2 border-b-2 flex-grow pb-12 pt-16 px-12 capitalize',
                    {
                      'border-grey-900 text-grey-900': selected,
                      'border-transparent text-grey-300 active:text-grey-400 hover:text-grey-400': !selected,
                    },
                  )}
                  Tag="span"
                >
                  {header.title}
                </Paragraphs.SM>
              )}
            </Tab>
          ))
        }
      </Tab.List>

      <Tab.Panels as="div" className="flex flex-grow pt-16 pb-24 px-25">
        {children}
      </Tab.Panels>
    </Tab.Group>
  )
}

export default AuthSectionLayout
