import Form, { SelectOption } from 'components/form/Home'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import ApiClient from 'data/api/api_client'
import useErrorHandler from 'data/hooks/useErrorHandler'
import useAuth from 'data/hooks/User/useAuth'
import AuthSectionPanel, { AuthSectionPanelProps, AuthSectionPanelSsoText } from 'components/auth/AuthSectionPanel'
import { RegisterFields } from 'data/api/auth_api_client'
import { useState } from 'react'
import ModalNavControl from 'components/ModalNavControl'
import IconButton from 'components/IconButton'
import { ArrowLeftIcon } from 'components/icons'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import { config } from 'data/config'
import { AuthSectionLayoutId } from './AuthSectionLayout'

interface RegisterSectionProps extends Pick<AuthSectionPanelProps, 'buttonStyle'> {}

type RegisterFormFields = Omit<RegisterFields, 'phone_country_code' | 'booking_channel'> & {
  booking_channel: SelectOption | undefined
  phone_country_code: SelectOption | undefined
}

const RegisterSection = ({
  ...props
}: RegisterSectionProps) => {
  const [createAccount, setCreateAccount] = useState(false)
  const { isMobileApp } = useMobileApp()

  const handleError = useErrorHandler()

  const { login } = useAuth()
  const { control, register, handleSubmit, setError, formState: { errors }, reset } = useForm<RegisterFormFields>()

  const registerMutation = useMutation(
    (fields: RegisterFormFields) => ApiClient.auth.register({
      ...fields,
      booking_channel: fields.booking_channel?.value,
      phone_country_code: fields.phone_country_code?.value,
      is_mobile_app: isMobileApp ? true : false,
    }),
    {
      onSuccess: (user) => {
        reset()
        login(user)
      },
      onError: (error) => {
        handleError(error, setError)
      },
    },
  )

  const onSubmit = (details: RegisterFormFields) => {
    if (createAccount) {
      registerMutation.mutate(details)
    }
  }

  return (
    <AuthSectionPanel
      onSubmit={handleSubmit(onSubmit)}
      button={createAccount
        ? {
          text: 'Sign Up',
          type: 'submit',
          isLoading: registerMutation.isLoading,
        }
        : {
          text: 'Create An Account',
          type: 'button',
          onClick: (e) => {
            e.stopPropagation()
            setTimeout(() => setCreateAccount(true), 0)
          },
          isLoading: registerMutation.isLoading,
        }
      }
      socialLogin={{
        show: !createAccount,
        text: AuthSectionPanelSsoText.Register,
      }}
      {...props}
    >
      {createAccount
        ? (
          <>
            <Form.Input
              variant="grey"
              placeholder="First Name"
              error={errors?.first_name?.message}
              {...register('first_name')}
            />

            <Form.Input
              variant="grey"
              placeholder="Last Name"
              error={errors?.last_name?.message}
              {...register('last_name')}
            />

            <Form.Input
              variant="grey"
              placeholder="Email Address"
              error={errors?.email?.message}
              {...register('email')}
            />

            <Form.Input
              variant="grey"
              placeholder="Password"
              type="password"
              error={errors?.password?.message}
              {...register('password')}
            />

            <Form.Field className="relative flex gap-10">
              <Form.ControlledCountryCodeSelect
                control={control}
                name="phone_country_code"
                error={errors?.phone_country_code}
                onOpen={(isOpen) => {
                  if (isOpen) {
                    setTimeout(() => {
                      const modal = document.getElementById(AuthSectionLayoutId)

                      if (modal) {
                        modal.scrollTo(0, modal.scrollHeight)
                      }
                    }, 100)
                  }
                }}
                placeholder="Code"
                className={{
                  listbox: 'basis-1/3',
                  container: '!static',
                }}
              />

              <Form.Input
                className={{
                  wrapper: 'basis-2/3',
                }}
                variant="grey"
                placeholder="Your Phone Number"
                error={errors?.phone?.message}
                {...register('phone')}
              />
            </Form.Field>

            <Form.ControlledSelect
              control={control}
              name="booking_channel"
              error={errors?.booking_channel}
              placeholder="How did you hear about us?"
              options={config.bookingChannelOptions}
            />

            <Form.Checkbox
              className="leading-20"
              style="customer"
              error={errors?.privacy_accepted?.message}
              {...register('privacy_accepted')}
            >
              <span className="text-14 text-grey-800">I have read and accepted the </span>
              <ModalNavControl.Link
                className="font-semibold underline text-primary text-14"
                text="Privacy Policy"
                href="/privacy-policy"
                target="_blank"
              />
            </Form.Checkbox>
          </>
        )
        : null
      }

      {
        createAccount
          ? (
            <IconButton
              className="absolute z-50 top-14 left-14"
              onClick={() => setCreateAccount(false)}
              variant="modal-close"
            >
              <ArrowLeftIcon className="stroke-2" />
            </IconButton>
          )
          : null
      }
    </AuthSectionPanel>
  )
}

export default RegisterSection
