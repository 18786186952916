import { useEffect } from 'react'
import { store } from 'store'
import { Provider, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import ApiClient from 'data/api/api_client'
import { clearUser, setUser } from 'store/auth'
import { QueryClient, QueryClientProvider } from 'react-query'
import { NotifierContextProvider } from 'react-headless-notifier'
import { DefaultSeo } from 'next-seo'
import 'styles/globals.css'
import CookieConsentBar, { getCookieConsent } from 'components/CookieConsentBar'
import Script from 'next/script'
import { config } from 'data/config'
import logoTagline from 'assets/images/logo-tagline.jpg'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { withUrqlClient } from 'next-urql'
import { useRouter } from 'next/router'
import { dedupExchange, cacheExchange } from 'urql'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'
import 'react-dates/initialize'
import { useCookies } from 'react-cookie'
import { createPortal } from 'react-dom'
import { isServer } from 'data/helpers/ssr'

NProgress.configure({
  showSpinner: false,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const notificationConfig = {
  max: null,
  duration: 5000,
  position: 'bottomRight',
}

const InnerApp = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    (async function getCurrentUser () {
      try {
        const user = await ApiClient.users.getCurrentUser()
        dispatch(setUser(user))
      } catch (error) {
        dispatch(clearUser())
      }
    })()
  }, [])

  return children
}

const MyApp = ({ Component, pageProps }) => {
  const [cookies] = useCookies([config.cookies.safeAreaInsets])
  const router = useRouter()

  useEffect(() => {
    const handleStart = (url, { shallow }) => {
      if (!shallow) {
        NProgress.start()
      }
    }

    const handleStop = (url, { shallow }) => {
      if (!shallow) {
        NProgress.done()
      }
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router])

  useEffect(() => {
    const safeAreaInsets = cookies?.safeAreaInsets

    if (safeAreaInsets) {
      const [top, right, bottom, left] = safeAreaInsets.split(',')

      document.documentElement.style.setProperty('--safe-area-inset-top', `${top}px`)
      document.documentElement.style.setProperty('--safe-area-inset-right', `${right}px`)
      document.documentElement.style.setProperty('--safe-area-inset-bottom', `${bottom}px`)
      document.documentElement.style.setProperty('--safe-area-inset-left', `${left}px`)
    }
  }, [cookies.safeAreaInsets])

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <NotifierContextProvider config={notificationConfig}>
          <InnerApp>
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover" />
            {(config.environment === 'production' && getCookieConsent() === true) && (
              <Script
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');`,
                }}
              />
            )}

            <DefaultSeo
              defaultTitle="AMA Selections"
              openGraph={{
                type: 'website',
                site_name: config.siteName,
                url: config.appURL,
                images: [
                  {
                    url: logoTagline,
                    alt: 'AMA selections logo',
                  },
                ],
              }}
            />
            <Component {...pageProps} />

            {/*
              Create cookie consent bar in portal to ensure no
              Overlapping with dialogs
            */}
            {!isServer
              ? createPortal(
                <CookieConsentBar />,
                document.body,
              )
              : <CookieConsentBar />
            }
          </InnerApp>
        </NotifierContextProvider>
      </QueryClientProvider>
    </Provider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
}

export default withUrqlClient(
  (ssrExchange, context) => ({
    url: process.env.NEXT_PUBLIC_GRAPHQL_BASE_URL,
    exchanges: [dedupExchange, cacheExchange, ssrExchange, multipartFetchExchange],
    fetch: async (input, init) => {
      init.credentials = 'include'

      init.headers['Cookie'] = context?.req?.headers?.cookie
      init.headers['Origin'] = context?.req?.headers?.host

      // Pull the csrf token from the header
      let token = context?.req?.headers?.['X-XSRF-TOKEN']

      // If token not found, pull it from the cookie header
      if (!token) {
        token = (context?.req?.headers?.cookie ?? '').match(/XSRF-TOKEN=(.*);/)?.[1]
        if (token) {
          token = decodeURIComponent(token)
        }
      }

      // TODO: If still no token, make a get request to fetch one. This currently doesn't work so the graphql route is excluded
      // if (!token) {
      //   const response = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/auth/csrf-cookie`, {
      //     headers: init.headers,
      //     credentials: 'include'
      //   })
      //   token = response.headers.get('set-cookie').match(/(^|;\\s*)(XSRF-TOKEN)=([^;]*)/)?.[3]
      //   if (token) {
      //     token = decodeURIComponent(token)
      //   }
      // }

      init.headers['X-XSRF-TOKEN'] = token

      return fetch(input, init)
    },
  }),
  {
    ssr: true,
  },
)(MyApp)
