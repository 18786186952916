import { useEffect } from 'react'
import { setUnreadCount } from 'store/inbox'
import { useDispatch, useSelector } from 'react-redux'
import { useNetworkOnlyQuery } from './useNetworkOnlyQuery'
import ApiClient from 'data/api/api_client'
import { useQuery } from 'react-query'
import { graphql } from 'gql'
import { RootState } from 'store'

export const useUnreadCount = () => {
  const dispatch = useDispatch()

  const unreadCount = useSelector((state: RootState) => state.inbox.unreadCount)

  const [{ data, fetching }, refetchUnreadMessageCount] = useNetworkOnlyQuery({
    query: graphql(`
      query UnreadMessagesCount {
        currentUser {
          id
          unread_count
        }
      }
    `),
  })

  useEffect(() => {
    if (!fetching && data?.currentUser?.unread_count) {
      dispatch(setUnreadCount(data.currentUser.unread_count))
    }
  }, [data?.currentUser?.unread_count])

  const { data: unreadNotificationCount, refetch: refetchUnreadNotificationCount } = useQuery(
    'notifications',
    () => ApiClient.notifications.getNotifications({
      status: 'unread',
    }, {
      paginated: true,
    }),
    {
      select: data => data?.meta.total,
    },
  )

  return {
    unreadCount,
    refetchUnreadMessageCount,
    unreadNotificationCount,
    refetchUnreadNotificationCount,
  }
}
