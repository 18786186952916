import { useEffect, useState } from 'react'
import Pusher from 'pusher-js'
import ApiClient from 'data/api/api_client'
import { config } from 'data/config'

export const usePusher = (userId) => {
  const [pusher, setPusher] = useState()

  useEffect(() => {
    if (userId && !pusher) {
      setPusher(new Pusher(
        config.pusher.appKey,
        {
          cluster: config.pusher.cluster,
          authorizer: (channel, _) => {
            return {
              authorize: (socketId, callback) => {
                ApiClient.auth.pusherAuth({ channel, socketId })
                  .then(response => {
                    callback(false, response)
                  })
                  .catch(error => {
                    callback(true, error)
                  })
              },
            }
          },
        },
      ))
    }

    return () => pusher?.disconnect()
  }, [userId, pusher])

  return { pusher }
}
