import classNames from 'classnames'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import { ReactChild } from 'data/types/types'
import React from 'react'

interface DisclaimerProps {
  children: ReactChild
  className?: {
    container?: string,
    text?: string,
  }
  onClick?: () => void
}

const Disclaimer = ({
  children,
  className,
  onClick,
}: DisclaimerProps) => {
  const containerClasses = classNames(
    className?.container,
    'bg-blue-30 rounded-10 mb-30',
  )

  const getContent = () => (
    <Paragraphs.MD className={classNames(
      'leading-22 tracking-1/4 text-grey-800',
      className?.text,
    )}>
      {children}
    </Paragraphs.MD>
  )

  if (onClick) {
    return (
      <button
        onClick={onClick}
        type="button"
        className={containerClasses}
      >
        {getContent()}
      </button>
    )
  }

  return (
    <div className={classNames(
      className?.container,
      'bg-blue-30 rounded-10 mb-30',
    )}>
      {getContent()}
    </div>
  )
}

export default Disclaimer
