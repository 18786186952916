import { RestUser } from 'data/types/user'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { clearUser, setUser } from 'store/auth'
import { clearInbox } from 'store/inbox'
import { useMobileApp } from '../MobileApp/useMobileApp'
import { MobileMessageActions } from 'data/helpers/mobile/messageChannels'

const useAuth = (
  onLogin?: (user: RestUser) => void,
) => {
  const redirectPathnames = ['/login', '/register', '/login/[social]', '/app-onboarding']
  const { isMobileApp } = useMobileApp()

  const router = useRouter()
  const dispatch = useDispatch()

  const { user, checked } = useSelector((state: RootState) => ({
    user: state.auth.user,
    checked: state.auth.checked,
  }))

  const redirect = () => {
    if (redirectPathnames.includes(router.pathname)) {
      if (router.query?.redirect) {
        router.replace(decodeURIComponent(router.query?.redirect as string))
      } else {
        router.push(isMobileApp ? '/home' : '/')
      }
    }
  }

  const login = (user: RestUser) => {
    dispatch(setUser(user))
    redirect()

    // If the user is on mobile upon change,
    // fire the event to request the FCM Token
    if (isMobileApp) {
      AmaMobileApp.postMessage(MobileMessageActions.GET_TOKEN)
    }
  }

  const logout = () => {
    dispatch(clearUser())
    dispatch(clearInbox())

    router.push('/login')
  }

  useEffect(() => {
    if (checked && user) {
      redirect()
      onLogin?.(user)
    }
  }, [user, checked])

  return {
    user,
    checked,
    login,
    logout,
    redirect,
  }
}

export default useAuth
