import { joinBy } from '@ama-selections/ui'
import { config } from 'data/config'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[];
  }
}

interface triggerInquiryFormInput {
  email?: string | null
  countryCode?: string | null
  phone?: string | null
}

export const triggerInquiryForm = ({
  email = null,
  countryCode = null,
  phone = null,
}: triggerInquiryFormInput) => {
  if (config.environment === 'production') {
    window.dataLayer.push({
      event: 'triggerInquiryForm',
      email: email,
      phoneNumber: joinBy([
        '+',
        countryCode,
        phone,
      ], ''),
    })
  }
}
