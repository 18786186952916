import { useEffect, useState } from 'react'

export enum MobileScreenWidths {
  MD = 768,
  LG = 1024,
  XL = 1280,
}

export const useScreenWidth = (mobileWidth = 768, initialState = false) => {
  const [width, setScreenWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(initialState)

  useEffect(() => {
    const updateWindowDimensions = () => {
      setScreenWidth(window.innerWidth)
      setIsMobile(window.innerWidth < mobileWidth)
    }
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])

  return { width, isMobile }
}
