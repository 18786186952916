import { graphql } from 'gql'

const DestinationSearchResultsQuery = graphql(`
  query DestinationSearchResults(
    $countriesFilter: CountriesFilterInput, 
    $regionsFilter: RegionsFilterInput, 
    $subRegionsFilter: SubRegionsFilterInput
    $citiesFilter: CitiesFilterInput,
  ) {
    countries(first: 5, filters: $countriesFilter) {
      data {
        id
        value
        slug
      }
    }

    regions(first: 5, filters: $regionsFilter) {
      data {
        id
        value
        slug
        country {
          id
          value
          slug
        }
      }
    }

    subRegions(first: 5, filters: $subRegionsFilter) {
      data {
        id
        value
        slug
        region {
          id
          slug
          value
          country {
            id
            value
            slug
          }
        }
      }
    }

    cities(first: 5, filters: $citiesFilter) {
      data {
        id
        value
        subRegion {
          id
          value
          slug
          region {
            id
            value
            slug
            country {
              id
              value
              slug
            }
          }
        }
      }
    }
  }
`)

export {
  DestinationSearchResultsQuery,
}
