import React, { useMemo } from 'react'
import Button from 'components/home/common/Button'
import IconButton from 'components/IconButton'
import { CrossThinIcon } from 'components/icons'
import Headers from 'components/home/common/typography/Headers'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import Image from 'components/Image'
import { config } from 'data/config'
import appLogo from 'assets/images/logo-app.png'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { setIsShowDownloadBannerOpen } from 'store/auth'

const DownloadAppBanner = () => {
  const dispatch = useDispatch()

  const isShowDownloadBannerOpen = useSelector((state: RootState) => state.auth.isShowDownloadBannerOpen)

  const mobileStoreUrl = useMemo(() => {
    const userAgent = (navigator.userAgent || navigator.vendor || ('opera' in window ? String(window.opera) : ''))

    if (/android/i.test(userAgent)) {
      return config.mobileStore.android
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return config.mobileStore.ios
    }
  }, [])

  if (!isShowDownloadBannerOpen) {
    return null
  }

  return (
    <div className="z-10 flex items-center justify-between w-screen pb-8 bg-white border-b pt-safe-offset-8 px-15 border-b-grey-200">
      <div className="flex items-center gap-8">
        <IconButton variant="modal-close" className="!text-14" onClick={() => dispatch(setIsShowDownloadBannerOpen(false))}>
          <CrossThinIcon />
        </IconButton>

        <div className="flex items-center justify-center px-2 h-45 w-45 rounded-10 shadow-banner-app-logo">
          <Image src={appLogo} />
        </div>

        <div className="flex flex-col justify-between h-full gap-2 ml-2">
          <Headers.H6 className="!font-sans">
            Get the app
          </Headers.H6>

          <Paragraphs.XXS className="!text-10 max-w-[150px] text-grey-700">
            A better, seamless way to experience A.M.A Selections
          </Paragraphs.XXS>
        </div>
      </div>

      <Button
        style="mini"
        className={{ button: 'whitespace-nowrap !rounded-16' }}
        href={mobileStoreUrl}
        target="_blank"
      >
        Get app
      </Button>
    </div>
  )
}

export default DownloadAppBanner
