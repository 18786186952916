import { CheckIcon } from 'components/icons'

interface CheckboxIconProps {
  checked?: boolean
}

export default function CheckboxIcon ({
  checked,
}: CheckboxIconProps) {
  return (
    <div className="flex items-center justify-center min-w-[24px] w-24 h-24 lg:min-w-[22px] lg:w-[22px] lg:h-[22px] rounded-full bg-grey-200 group-focus:ring-2 group-focus:ring-primary-100">
      {
        checked && (
          <div className="flex items-center justify-center w-24 h-24 lg:w-[22px] lg:h-[22px] rounded-full bg-primary">
            <CheckIcon className="text-white text-24 lg:text-22" />
          </div>
        )
      }
    </div>
  )
}
