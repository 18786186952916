import { Dialog } from '@headlessui/react'
import Image, { StaticImageData } from 'components/Image'
import { CrossIcon, RotateIcon } from 'components/icons'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import WishlistModalTrigger from '../triggers/WishlistModalTrigger'
import useOrientation, { BasicOrientation } from 'data/hooks/useOrientation'
import classNames from 'classnames'
import Pill from 'components/home/Pill'
import NavigationButton from 'components/NavigationButton'
import { defaultCarouselProps } from '@ama-selections/ui'
import { useCarouselLoadedImages } from 'data/hooks/useCarouselLoadedImages'

interface FullscreenImageModalProps {
  isOpen: boolean
  onClose: () => void
  images: {
    url: string | StaticImageData
    alt_tag?: string
    alt_text?: string
  }[]
  startingImageIndex?: number
  propertyId?: string
}

const FullscreenImageModal = ({
  isOpen,
  onClose,
  images,
  propertyId,
  startingImageIndex = 0,
}: FullscreenImageModalProps) => {
  const imagesEitherSide = 2
  const { onRealIndexChange, isImageLoaded } = useCarouselLoadedImages({
    imagesEitherSide,
    imagesLength: images!.length,
    isLoop: true,
  })

  const [currentSlide, setCurrentSlide] = useState(startingImageIndex)
  const navigationNextRef = React.useRef(null)
  const navigationPrevRef = React.useRef(null)
  const [isManuallyRotated, setIsManuallyRotated] = useState(false)

  const prevButtonClasses = classNames(
    'swiper-button-property-images-prev left-safe-offset-[28px]',
    {
      'absolute top-0 left-[50%] mt-safe-offset-24 rotate-90': isManuallyRotated,
    },
  )

  const nextButtonClasses = classNames(
    'swiper-button-property-images-next right-safe-offset-[28px]',
    {
      'absolute bottom-0 left-[50%] -rotate-90 mb-safe-offset-24': isManuallyRotated,
    },
  )

  const buttonClasses = classNames('absolute cursor-pointer transition-none',
    {
      '-mt-14 top-1/2': !isManuallyRotated,
    },
  )

  const { orientation } = useOrientation()

  // Reset rotation on orientation change, this exists
  // because some users may not know how to rotate back
  useEffect(() => {
    setIsManuallyRotated(false)
  }, [orientation])

  const defaultGridItemClassName = 'flex pointer-events-auto'

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Panel className="fixed top-0 left-0 z-50 w-screen h-screen bg-white">

        <div className="absolute z-20 grid w-screen h-screen grid-cols-3 grid-rows-3 pointer-events-none">

          {/* Rotate button */}
          <div className={classNames(defaultGridItemClassName, {
            'row-start-3 col-start-2 mb-18 justify-center items-end': !isManuallyRotated,
            'row-start-2 col-start-1 justify-start items-center': isManuallyRotated,
          })}>
            <Pill
              variant="translucent-dark"
              onClick={() => setIsManuallyRotated(!isManuallyRotated)}
              className={classNames(
                '!font-poppins !font-medium pointer-events-auto lg:hidden lg:pointer-events-none',
                {
                  'rotate-90': isManuallyRotated,
                },
              )}
            >
              <RotateIcon className="w-12 h-12" />
              <span>Rotate</span>
            </Pill>
          </div>

          {/* Wishlist Button */}
          {
            propertyId && (
              <div className={classNames(
                defaultGridItemClassName,
                {
                  'row-start-1 col-start-3 justify-end p-24': !isManuallyRotated && orientation === BasicOrientation.Portrait,
                  'row-start-3 col-start-1 justify-start items-end px-[28px] py-20': isManuallyRotated && orientation === BasicOrientation.Portrait,
                  'row-start-3 col-start-3 justify-end items-end px-[28px] py-20': orientation === BasicOrientation.Landscape,
                },
              )}>
                <WishlistModalTrigger
                  propertyId={propertyId}
                  buttonVariant="whiteHeart"
                  className={isManuallyRotated ? 'rotate-90' : ''}
                />
              </div>
            )
          }

          {/* Close Button */}
          <div className={classNames(
            defaultGridItemClassName,
            {
              'row-start-1 col-start-1 justify-start items-start p-24': !isManuallyRotated && orientation === BasicOrientation.Portrait,
              'row-start-3 col-start-3 justify-end items-end px-[28px] py-20': isManuallyRotated && orientation === BasicOrientation.Portrait,
              'row-start-1 col-start-3 justify-end items-start px-[28px] py-20': orientation === BasicOrientation.Landscape,
            },
          )}>
            <button onClick={onClose} type="button" className="flex items-center justify-center h-[34px] w-[34px] rounded-4 focus:ring-2 focus:ring-primary-fresh">
              <CrossIcon className="text-white text-24 hover:text-grey-300" />
            </button>
          </div>

          {/* Progress */}
          <div className={classNames(
            defaultGridItemClassName,
            {
              'row-start-1 col-start-2 justify-center items-start': !isManuallyRotated,
              'row-start-2 col-start-3 justify-end items-center': isManuallyRotated,
              'mt-16': orientation === BasicOrientation.Landscape && !isManuallyRotated,
              'mt-24': orientation === BasicOrientation.Portrait && !isManuallyRotated,
            },
          )}>
            <Pill variant="translucent-dark" className={isManuallyRotated ? 'rotate-90' : ''}>
              {currentSlide} of {images.length}
            </Pill>
          </div>
        </div>

        <Swiper
          {...defaultCarouselProps}
          modules={[Navigation]}
          initialSlide={startingImageIndex}
          className="h-full bg-grey-900"
          loop={true}
          direction={isManuallyRotated ? 'vertical' : 'horizontal'}
          onSlideChange={(swiper: SwiperClass) => setCurrentSlide(swiper.realIndex + 1)}
          onRealIndexChange={(swiper: SwiperClass) => onRealIndexChange(swiper.realIndex)}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onSwiper={(swiper: SwiperClass) => {
            setTimeout(() => {
              if (swiper.params.navigation && typeof swiper.params.navigation !== 'boolean') {
                swiper.params.navigation.prevEl = navigationPrevRef.current
                swiper.params.navigation.nextEl = navigationNextRef.current
              }
              swiper.navigation.destroy()
              swiper.navigation.init()
              swiper.navigation.update()
            })
          }}
          observer={true}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index} className="relative w-full h-full">
              <div className="absolute inset-0">
                <div className={classNames(
                  'relative w-full h-full',
                  // {
                  //   'rotate-90': isManuallyRotated && orientation === BasicOrientation.Portrait,
                  //   '-rotate-90': isManuallyRotated && orientation === BasicOrientation.Landscape,
                  // },
                )}>
                  {
                    isImageLoaded(index) && (
                      <Image
                        src={image.url}
                        alt={image.alt_text}
                        layout="fill"
                        className={classNames(
                          'object-contain',
                          {
                            'rotate-90': isManuallyRotated && orientation === BasicOrientation.Portrait,
                            '-rotate-90': isManuallyRotated && orientation === BasicOrientation.Landscape,
                          },
                        )}
                        sizes="(max-width: 400px) 400px,
                          (max-width: 600px) 600px,
                          (max-width: 768px) 800px,
                          (max-width: 1536px) 1080px,
                          1440px"
                        priority
                      />
                    )
                  }
                </div>
              </div>
            </SwiperSlide>
          ))}

          <NavigationButton
            navigationRef={navigationPrevRef}
            direction="left"
            buttonClasses={buttonClasses}
            images={images}
            leftButtonClasses={prevButtonClasses}
            rightButtonClasses={prevButtonClasses}
          />

          <NavigationButton
            navigationRef={navigationNextRef}
            direction="right"
            buttonClasses={buttonClasses}
            images={images}
            leftButtonClasses={nextButtonClasses}
            rightButtonClasses={nextButtonClasses}
          />
        </Swiper>
      </Dialog.Panel>
    </Dialog>
  )
}

export default FullscreenImageModal
