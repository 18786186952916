import { isServer } from 'data/helpers/ssr'
import { useEffect, useState } from 'react'
import { AnyVariables, OperationContext, RequestPolicy, TypedDocumentNode, UseQueryResponse, useQuery } from 'urql'

export interface UseNetworkOnlyQueryProps<T, K> {
  query: TypedDocumentNode<T, K>
  variables?: Omit<K, 'page'>
  requestPolicy?: never
  context?: Partial<OperationContext>
  pause?: boolean
}

export const useNetworkOnlyQuery = function <T, K extends AnyVariables> (
  query: UseNetworkOnlyQueryProps<T, K>,
) {
  const [requestPolicy, setRequestPolicy] = useState<RequestPolicy>('cache-first')

  const props = useQuery<T, AnyVariables>({
    ...query,
    requestPolicy: isServer ? 'network-only' : requestPolicy,
  }) as unknown as UseQueryResponse<T, K>

  useEffect(() => {
    // After mount set request policy to network only
    if (requestPolicy === 'cache-first') {
      setRequestPolicy('network-only')
    }
  }, [props[0].data])

  return props
}
